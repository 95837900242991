import { Link } from "components/link/Link.component";
import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import React from "react";
import linksService from "services/links.service";

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not Found" />
      <div className="layout fix-header-overlap device-height">
        <h1>404: Not found</h1>
        <p>Sorry, we couldn’t find what you were looking for.</p>
        <Link to={linksService.home()}>Return to home page</Link>.
      </div>
    </>
  );
};

export default NotFoundPage;
